
.icon-button {
  background: var(--background-color);
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.icon-button:hover {
  filter: invert(0.1);
}

.icon-button:active:focus {
  filter: invert(0.2);
}

.icon-button img {
  width: 40px;
  height: 40px;
}

