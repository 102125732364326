.game-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
}

.app-logo {
  height: 2em;
  width: 2em;
  transform: translate(0.000015em, 0.00em);
  position: absolute;
  left: 0;
  user-select: none;
}

.app-logo-link {
  height: 2em;
  font-size: calc(10px + 1vmin);
  color: var(--text-color-1);
  text-decoration: none;
  position: relative;
}

.app-logo-link h1 {
  margin: 0;
  font-size: 2em;
  line-height: calc(20px + 2vmin);
}

.app-logo-link h1::selection {
  color: var(--text-color-1);
  background: var(--selected-color);
}

.app-logo-link h1 span {
  color: transparent;
}

.app-logo-link h1 span::selection {
  color: transparent;
  background: var(--selected-color);
}

.game-view .intro-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.game-view-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-view-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}



.popup-overlay {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-picker-popup {
  z-index: 1;
  min-width: 200px;
  max-width: 90vw;
  max-height: 90vh;
  padding: 20px;
  border: 1px solid var(--text-color-1);
  border-radius: 4px;
  background-color: var(--background-color);
  overflow: auto;
}

.light-theme .game-picker-popup {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.dark-theme .game-picker-popup {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.8), 0 6px 20px 0 rgba(0,0,0,0.7);
}

.help-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 30px;
}

.help-popup {
  z-index: 1;
  min-width: min(300px, 75%);
  max-width: 800px;
  max-height: 90vh;
  padding: 20px;
  border: 1px solid var(--text-color-1);
  border-radius: 4px;
  background-color: var(--background-color);
  overflow: auto;
}

.help-popup .help-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


/* When the browser width is <= 650px */
@media screen and (max-width: 650px) {
  .help-popup {
    padding: 10px;
  }

  .help-overlay {
    padding: 15px;
  }
}







