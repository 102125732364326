
.share-button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.share-button .share-icon {
  width: 20px;
}
