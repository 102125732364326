.word-join-puzzle {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 25px;
  color: var(--text-color-1);
  font-family: monospace;
  text-transform: uppercase;
}

.word-join-puzzle form {
  height: 30px;
  margin-top: 0;
  padding-top: 0;
  display: flex;
}

.word-join-puzzle input {
  background: transparent;
  height: 30px;
  border: 1px solid var(--text-color-1);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0 0.3em;
  margin: 0;
  font-size: 25px;
  width: 170px;
  color: var(--text-color-1);
  outline: none;
  text-transform: uppercase;
}

