

.puzzle-keyboard {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.puzzle-keyboard .keyboard-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}


.puzzle-keyboard .keyboard-key {
  background: var(--pass-color);
  border: none;
  position: relative;
  width: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
}

/* When the browser width is <= 650px */
@media screen and (max-width: 650px) {
  .puzzle-keyboard .keyboard-key {
    width: 30px;
    height: 40px;
  }
}

.puzzle-keyboard .keyboard-key:disabled {
    background: var(--disabled-background-color);
}

.puzzle-keyboard .keyboard-key.keyboard-key-invalid {
    background: red;
}

.puzzle-keyboard .key-letter {
    font-size: 18px;
}

.puzzle-keyboard .key-number {
    position: absolute;
    top: 1px;
    right: 2px;
    font-size: 14px;
}
.puzzle-keyboard .keyboard-key:disabled:not(.keyboard-key-invalid) .key-number {
    visibility: hidden;
}
