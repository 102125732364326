.game-picker {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  gap: 5px;
}

.game-picker .game-picker-game.game-picker-current {
  background: var(--pass-color);
}

.game-picker .game-picker-game.game-picker-solved {
  background: var(--gold-color);
}
