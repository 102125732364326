
.word-join-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-width: 360px;
  width: 99%;
}

.word-join-game-name {
  font-size: 35px;
}

.word-join-game .word-join-game-puzzles {
  max-width: 800px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.word-join-game .word-join-puzzle {
  position: absolute;
}

.word-join-game .success-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.word-join-game .result-string {
  font-size: 20px;
  max-width: 90%;
  overflow-wrap: break-word;
}
