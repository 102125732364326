body {
  background-color: var(--background-color);
  color: var(--text-color-1);
  min-width: 380px;
}

.app {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.app-header {
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 1vmin);
  gap: 10px;
  padding: 10px 0;
  width: 445px;
}

/* When the browser width is <= 650px */
@media screen and (max-width: 650px) {
  .app-header {
    width: 345px;
  }
}

.app-header-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
