body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color-1);
}

:root {
  --black: #000000;
  --white: #FFFFFF;
  --dark: #222126;
  --light-green: #01C10E;
  --dark-green: #007C0C;
  --light-yellow: #F7DE25;
  --dark-yellow: #7C7C00;
  --red: #FF0505;
  --gray: #939393;
  --dark-gray: #515151;
  --light-blue: #BCD8FF;
  --dark-blue: #100289;
}

/**
 user system prefers light theme, sets initial background
 */
:root,
body.light-theme {
  --background-color: var(--white);
  --text-color-1: var(--black);
  --selected-color: var(--light-blue);
  --disabled-background-color: var(--gray);
  --pass-color: var(--light-green);
  --gold-color: var(--light-yellow);
}

body.dark-theme {
  --background-color: var(--dark);
  --text-color-1: var(--white);
  --selected-color: var(--dark-blue);
  --disabled-background-color: var(--gray);
  --pass-color: var(--dark-green);
  --gold-color: var(--dark-yellow);
}

@media (prefers-color-scheme: dark) {
  /* user system prefers dark theme, sets initial background */
  :root {
    --background-color: var(--dark);
    --text-color-1: var(--white);
    --selected-color: var(--dark-blue);
    --disabled-background-color: var(--gray);
    --pass-color: var(--dark-green);
    --gold-color: var(--dark-yellow);
  }
}

.app .wj-button {
  background: var(--background-color);
  height: 30px;
  border: 1px solid var(--text-color-1);
  border-radius: 2px;
  font-family: "monospace";
  font-size: 18px;
  color: var(--text-color-1);
}

.light-theme .app .wj-button:not(:disabled):active:focus {
  filter: brightness(0.8);
}
.dark-theme .app .wj-button:not(:disabled):active:focus {
  filter: brightness(0.7);
}

.light-theme .app .wj-button:not(:disabled):hover {
  filter: brightness(0.9);
}
.dark-theme .app .wj-button:not(:disabled):hover {
  filter: brightness(0.9);
}


a {
  color: var(--text-color-1);
  background: var(--background-color);
}

a:hover {
  filter: brightness(0.8);
}

a:focus:active {
  filter: brightness(0.7);
}
